import { useEffect } from "react";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { FranchiseState } from "redux/reducer/Franchise/types";
import { setFranchiseFooterCMSData } from "redux/reducer/Franchise/FranchiseActions";
import { IC_BACK_TO_TOP } from "assets/images";
import FooterSocialIconsContainer from "../FooterSocialIconsContainer";
import { FooterContainer, FooterLinks, FooterNavLinks, FrachisorCopyrightNotice } from "./FrachiseFooterStyled";
import FranchiseFooterLogo from "./FranchiseFooterLogo";

type RootState = {
  franchise: FranchiseState;
};

const FranchiseFooter = () => {
  const dispatch = useDispatch();
  const { franchiseFooterCMS, isFooterCMSLoaded } = useSelector((state: RootState) => ({
    franchiseFooterCMS: state.franchise.franchiseFooter,
    isFooterCMSLoaded: state.franchise.isFranchiseFooterLoaded,
  }));

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (typeof isFooterCMSLoaded === "boolean" && !isFooterCMSLoaded) {
      dispatch(setFranchiseFooterCMSData());
    }
  }, [isFooterCMSLoaded]);

  const socialLinks = franchiseFooterCMS?.SocialLinks && <FooterSocialIconsContainer socialLinks={franchiseFooterCMS.SocialLinks} />;

  return (
    <FooterContainer id="franchise-footer">
      <div className="boxShadow">
        <div className="franchiseFooter">
          <div className="footerGoButton">
            <Image src={IC_BACK_TO_TOP} onClick={scrollToTop} alt="scroll top" />
          </div>
          <div className="footerBottom">
            <div className="footerPrimaryLink">
              <FooterLinks>
                {franchiseFooterCMS?.FranFooterMainLinks?.map((link, idx) => {
                  return (
                    <FooterNavLinks key={idx} to={link?.LinkURL ?? ""}>
                      {link?.LinkText}
                    </FooterNavLinks>
                  );
                })}
              </FooterLinks>
              <div className="franchiseSocailLinks">{socialLinks}</div>
            </div>
            {franchiseFooterCMS?.ZaxbysCopyRight && (
              <FranchiseFooterLogo
                copyrightText={franchiseFooterCMS?.ZaxbysCopyRight}
                showLinks
                footerLogo="frachise"
                secondayLinks={franchiseFooterCMS?.FranFooterSecondaryLinks}
                logo={franchiseFooterCMS?.FooterLogo?.url}
              />
            )}
          </div>

          {franchiseFooterCMS?.ZaxbysCopyRight && (
            <FrachisorCopyrightNotice>{franchiseFooterCMS?.ZaxbysCopyRight}</FrachisorCopyrightNotice>
          )}
        </div>
      </div>
    </FooterContainer>
  );
};

export default FranchiseFooter;
