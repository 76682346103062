import Markdown, { MarkdownToJSX } from "markdown-to-jsx";
import HeadingOverride from "../MarkdownOverrides/HeadingOverride/HeadingOverride";
import LinkOverride from "../MarkdownOverrides/LinkOverride/LinkOverride";
import ParagraphOverride from "../MarkdownOverrides/ParagraphOverride/ParagraphOverride";
interface MarkdownProps {
  markdown: string;
  className?: string;
  overrides?: MarkdownToJSX.Overrides;
}
const MarkdownComponent = ({ markdown, className, overrides }: MarkdownProps) => {
  return (
    <Markdown
      className={className}
      options={{
        overrides: {
          a: {
            component: LinkOverride,
          },
          p: {
            component: ParagraphOverride,
          },
          h: {
            component: HeadingOverride,
          },
          span: {
            component: ParagraphOverride,
          },
          ...overrides,
        },
      }}
    >
      {markdown}
    </Markdown>
  );
};
export default MarkdownComponent;
