import { useState } from "react";
import Image from "next/image";
import theme from "theme/theme";
import { FranchiseNavLink } from "types/cms/footer";
import { FooterLogoContainer, FooterLogoLinks } from "./FrachiseFooterStyled";

interface Props {
  copyrightText?: string;
  showLinks?: boolean;
  footerLogo: "frachise" | "zaxbys";
  secondayLinks?: FranchiseNavLink[];
  logo?: string | null;
}

const FranchiseFooterLogo = ({ showLinks = false, footerLogo, logo, secondayLinks = [] }: Props) => {
  const [isPopped, setPop] = useState(0);
  const onButtonClick = () => {
    setPop(isPopped + 1);
    if (isPopped >= 1) {
      window.location.reload();
    }
  };
  return (
    <FooterLogoContainer>
      <div className="FooterImageWrapper">
        {logo && (
          <Image
            id="zax-footer-logo"
            src={logo}
            alt={footerLogo}
            loader={() => `${logo}`}
            width={288}
            height={105}
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </div>

      <div className="footerNavSecondaryLink">
        {showLinks &&
          secondayLinks?.map((link, idx) => {
            return (
              <FooterLogoLinks key={idx} to={link?.LinkURL ?? ""}>
                {link?.LinkText}
              </FooterLogoLinks>
            );
          })}
        {showLinks && (
          <button
            onClick={onButtonClick}
            id="ot-sdk-btn"
            className="ot-sdk-show-settings footerBottomLink"
            style={{
              background: "none",
              color: theme.color.primary.foundersBlue,
              border: "none",
              padding: 0,
              fontWeight: "700 !important",
              fontSize: 12,
              textAlign: "left",
              marginTop: 8,
              fontFamily: theme.font.barlow,
              cursor: "pointer",
              outline: "inherit",
            }}
          >
            Cookie Settings
          </button>
        )}
      </div>
    </FooterLogoContainer>
  );
};

export default FranchiseFooterLogo;
