import { useSelector } from "react-redux";
import useModal from "hooks/useModal";
import { RootState } from "src/types/types";
import Button from "components/atomic-components/atoms/button/Button";
import Headings from "components/atomic-components/atoms/typography/Headings/Headings";
import Paragraph from "components/atomic-components/atoms/typography/Paragraphs/Paragraph";
import AuthCard from "components/Auth/AuthCard/AuthCard";
import StackedButtonsContainer from "components/global/StackedButtonsContainer/StackedButtonsContainer";
import Modal from "components/global/Modal/Modal";
import { GetStartedComfirmationStyled } from "./getStartedComfirmationStyled";

const GetStartedConfirmationModal = () => {
  const { modal, setModal } = useModal();

  const { getStartedMessage } = useSelector((state: RootState) => ({
    getStartedMessage: state?.franchise?.getStartedMessage,
  }));

  const onCloseModal = () => {
    setModal("");
  };

  const FOOTER = (
    <StackedButtonsContainer direction="col" mobileDirection="col" className="stackedButton">
      <Button variant="primaryRed" onClick={onCloseModal} className="pickupButton">
        OK
      </Button>
    </StackedButtonsContainer>
  );

  const BODY = (
    <div className="headings">
      <Headings variant="primaryHeading1" className="title">
        {getStartedMessage?.message}
      </Headings>

      <Paragraph variant="secondaryParagraph4" className="subHeading">
        {getStartedMessage?.description}
      </Paragraph>
    </div>
  );

  return (
    <GetStartedComfirmationStyled>
      <Modal isOpen={modal === "FRANCHISE_GET_STARTED_CONFIRMATION"} className="modal">
        <div className="selectStoreModalContainer">
          <AuthCard
            className="authCardStoreModal"
            body={BODY}
            footer={FOOTER}
            isCloseRequired={true}
            isCloseRequiredInMobile={false}
            onClose={() => setModal("")}
            isButtonTextVisible={true}
          />
        </div>
      </Modal>
    </GetStartedComfirmationStyled>
  );
};
export default GetStartedConfirmationModal;
