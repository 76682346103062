import styled from "styled-components";
import Link from "components/global/Link/Link";

export const FooterContainer = styled.footer`
  .boxShadow {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px 0px;
  }
  .franchiseFooter {
    width: 100%;
    max-width: 1280px;
    margin: auto;
    background: ${(props) => props.theme.color.core.white};
    padding-bottom: 30px;
    position: relative;
    .footerBottom {
      padding: 48px 120px 0 120px;
      display: flex;
      background: ${(props) => props.theme.color.core.white};
      justify-content: space-between;
      .franchiseSocailLinks {
        padding-top: 61px;
        .socialIcons {
          a {
            width: 36px !important;
            height: 36px !important;
          }
        }
      }
    }
    .footerGoButton {
      position: absolute;
      top: -25px;
      right: 47px;
      cursor: pointer;
      z-index: 10;
      @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.md}) {
        display: none;
      }
    }

    @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
      .footerBottom {
        padding: 40px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 10;
        .franchiseSocailLinks {
          padding-top: 32px;
          display: flex;
          width: 100%;
          justify-content: center;
        }
      }
      .footerPrimaryLink {
        width: 100%;
      }
      .logo-wrapper {
        display: none;
      }
      padding-bottom: 30px;
    }
  }
`;

export const FooterLogoContainer = styled.div`
  text-align: center;
  grid-column: span 3;
  > *:first-child {
    width: 245px;
    height: 108px;
  }
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    margin-top: 40px;
    > *:first-child {
      width: 245px;
      height: 108px;
      margin-left: 8px;
    }
  }
  .FooterImageWrapper {
    width: 288px;
    height: 105px;
    position: relative;
  }
  .footerNavSecondaryLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 56px;
  }
  .footerBottomLink {
    font-size: 12px !important;
    font-weight: 500 !important;
    text-align: center;
  }
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    .footerNavSecondaryLink {
      align-items: center;
      margin-left: 0px;
      width: 100%;
      margin-top: 40px;
      .footerBottomLink {
        font-size: 13px;
        font-weight: 700 !important;
        line-height: 20px;
        font-family: ${(props) => props.theme.font.barlowSemiCondensed};
        text-align: center;
        text-decoration: underline !important;
        text-transform: uppercase;
        margin-top: 3px;
      }
    }
  }
`;

export const FooterLogoLinks = styled(Link)`
  font-family: ${(props) => props.theme.font.barlow};
  display: flex;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  text-decoration-skip-ink: none !important;
  font-size: 12px;
  font-weight: 500;
  text-align: center !important;
  justify-content: center;
  line-height: 24px;
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    font-family: ${(props) => props.theme.font.barlowSemiCondensed};
    text-align: center;
    text-decoration: underline !important;
    text-transform: uppercase;
    margin-top: 3px;
  }
`;

export const FooterLinks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px 63px;
  height: 210px;
  flex-wrap: wrap;
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    gap: 10px;
    height: 100%;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
  }
`;

export const FooterNavLinks = styled(Link)`
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  font-family: ${(props) => props.theme.font.barlowSemiCondensed};
  color: ${(props) => props.theme.color.primary.foundersBlue};
  text-transform: uppercase;
`;

export const FrachisorCopyrightNotice = styled.p`
  font-weight: 500;
  font-size: 13px;
  color: ${(props) => props.theme.color.primary.foundersBlue};
  text-align: center;
  font-family: ${(props) => props.theme.font.barlow};
  margin-top: 91px;
  @media only screen and (max-width: ${(props) => props.theme.franchiseBreakpoints.lg}) {
    margin-top: 0;
  }
`;
