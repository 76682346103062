import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  color: {
    core: {
      red: "#A6192E",
      blue: "#0C2340",
      white: "#FFFFFF",
      black: "#000000",
    },

    /* Rebrand Primary */
    primary: {
      tongueTorchRed: "#E4002B",
      coleslawWhite: "#F7F3ED",
      foundersBlue: "#041E42",
      pelletIceBlue: "#41B6E6",
    },

    secondary: {
      red: "#E4002A",
      blue: "#0E183F",
      newBlue: "#1B3962",
      mildBlue: "#003087",
      darkRed: "#701523",
      lightBlue: "#EAF1F4",
      offWhite: "#F9F7EF",
      lightGrey: "#D4DDE0",
      orange: "#FF8200",
      pantone290: "#B9D9EB",
      pantone186: "#C8102E",
      pantone151: "#FF8200",
      pantone120: "#FBDB65",
      coolGray10: "#63666A",
      coolGray7: "#97999B",
      coolGray4: "#BBBCBC",
      coolGray1: "#D9D9D6",
      coolGray2: "#B7C7CC",
      coolGray3: "#E3E3E3",
      lightBlue0: "#5b6b74",
      green: "#009444",
      mediumBlue: "#0068CE",
      lightWhiteShade: "#f1f1f1",
      veryDarkRed: "#76232F",
      mercury: "#EAEAEA",
      lightGreyBorder: "#D9D9D9",
      gray5: "#E0E0E0",
      lightBlue1: "#003880",
      transparentRed: "#A6192E1A",
      transparentWhite: "#FFFFFFB2",
      lightBGBlue: "#b9d9eb00",
      yellow: "#FFA300",
      mildRed: "#EA0A2A",
      darkRed2: "#E4002B",
      darkWhite: "#cdd2d9",
      transparentDarkRed: "#E4002B4D", // rgba(228, 0, 43, 0.3) converted from Figma
      darkGray: "#434343",
      transparentDarkBlue: "#041E42CC",
    },
    limitedUse: {
      marinadeRed: "#8A1538",
      seasoningOrange: "#CF4520",
      secretSauceOrange: "#FF8200",
      texasToastYellow: "#F6B700",
      leafyGreen: "#78BE21",
      kosherPickleGreen: "#00A376",
      nuclearBlack: "#101820",
      pureWhite: "#FFFFFF",
      darkRed3: "#E40032",
      borderGray: "#E2E2E2",
      lightYellow: "#F7F2ED",
      downloadBlue: "#001F5F",
      darkRed4: "#AA0020",
      lightRed: " #ff6666",
      coleslawWhiteLight: "#fdfbfa",
    },
    gray: {
      coolGray11: "#4A4A4A",
      boxShadow: "#00000040",
      mobileBoxShadow: "#0000004D",
    },
    pseudo: {
      peach: "#eee",
      dawnPink: "#ECECEC",
      smokeWhite: "#f5f5f5",
    },
  },

  font: {
    sansRegular: "ZaxSansRegular",
    sansRough: "ZaxSansRough",
    scriptRegular: "ZaxScriptRegular",
    scriptCaps: "ZaxScriptCaps",
    scriptRough: "ZaxScriptRough",
    scriptRoughCaps: "ZaxScriptRoughCaps",
    serifRegular: "ZaxSerifRegular",
    serifBold: "ZaxSerifBold",
    serifDemiBold: "ZaxSerifDemiBold",
    serifExtraLight: "ZaxSerifExtraLight",
    serifHeavy: "ZaxSerifHeavy",
    serifLight: "ZaxSerifLight",
    serifMedium: "ZaxSerifMedium",
    sourceSansPro: "SourceSansPro",
    barlow: "Barlow",
    barlowSemiCondensed: "BarlowSemiCondensed",
    barlowCondensed: "BarlowCondensed",
    ZaxCasual: "ZaxCasual",
  },

  size: {
    xs: "375px",
    sm: "767px",
    md: "768px",
    lg: "992px",
    xl: "1279px",
  },

  devices: {
    xs: `only screen and (min-width: 375px)`,
    sm: `only screen and (min-width: 767px)`,
    md: `only screen and (min-width: 768px)`,
    lg: `only screen and (min-width: 992px)`,
    xl: `only screen and (min-width: 1279px)`,
  },

  responsive: {
    max: {
      xs: `@media only screen and (max-width: 375px)`,
      sm: `@media only screen and (max-width: 767px)`,
      md: `@media only screen and (max-width: 768px)`,
      lg: `@media only screen and (max-width: 992px)`,
      xl: `@media only screen and (max-width: 1279px)`,
    },

    min: {
      xs: `@media only screen and (min-width: 375px)`,
      sm: `@media only screen and (min-width: 767px)`,
      md: `@media only screen and (min-width: 768px)`,
      lg: `@media only screen and (min-width: 992px)`,
      xl: `@media only screen and (min-width: 1279px)`,
    },
  },
  franchiseBreakpoints: {
    xs: "375px",
    sm: "480px",
    md: "768px",
    lg: "960px",
    xl: "1279px",
  },
  contentContainerWidth: {
    lg: "1040px",
    xl: "1140px",
  },
};

export default theme;
